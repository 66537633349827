import React, { useContext, useMemo } from "react";

import styles from "./style.module.scss";
import { DutyPhone, NewDutyPhone } from "@models/dutyPhone";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";
import {
  Icons24Trash,
  ModalCell,
  ModalIcon,
  ModalLabel,
  ModalSpacer,
  PrimaryButton,
  Switch,
  ModalTextArea,
  Input,
  ModalHeader,
  Modal,
} from "@frontend/assaia-ui";
import ModalSmallDropdown from "../../Common/ModalSmallDropdown";
import { DutyPhoneModalStore } from "./DutyPhoneModalStore";
import { getConfig } from "../../../di/di";

type Props = {
  initialValue: DutyPhone | NewDutyPhone;
};

const DutyPhoneModal: React.FC<Props> = ({ initialValue }) => {
  const { phonesMask } = getConfig();
  const { dutyPhonesStore } = useContext(HomeContext);
  const {
    data,
    isNewDutyPhone,
    close,
    onPhoneChanged,
    submit,
    deleteDutyPhone,
    toggleActive,
    onDescriptionChanged,
    isNumberValid,
  } = useMemo(() => new DutyPhoneModalStore(dutyPhonesStore, initialValue), []);

  return (
    <Modal onClose={close}>
      <ModalHeader
        title={isNewDutyPhone ? "New number" : "Edit number"}
        onClose={close}
      >
        {!isNewDutyPhone && (
          <ModalSmallDropdown
            trigger={
              <ModalIcon onClick={() => {}}>
                <DotsSVG />
              </ModalIcon>
            }
          >
            <ModalCell
              onClick={deleteDutyPhone}
              title={<span className={styles.red}>Delete</span>}
              leftIcon={<Icons24Trash className={styles.red} />}
            />
          </ModalSmallDropdown>
        )}
      </ModalHeader>
      <ModalCell
        title="Enabled"
        onClick={() => toggleActive(!data.active)}
        leftIcon={<NotificationSVG />}
      >
        <Switch active={data.active}></Switch>
      </ModalCell>

      <ModalLabel title="Phone number">
        <Input
          mask={phonesMask}
          alwaysShowMask
          value={data.phone}
          onChange={onPhoneChanged}
          onClear={() => onPhoneChanged("")}
          immediateOnChange
        />
      </ModalLabel>

      <ModalLabel title={"Description"}>
        <ModalTextArea
          value={data.description}
          placeholder="Set description text for the duty phone..."
          onChange={onDescriptionChanged}
          maxLength={100}
        />
      </ModalLabel>

      <ModalSpacer />

      <PrimaryButton disabled={!isNumberValid} onClick={submit}>
        save
      </PrimaryButton>
    </Modal>
  );
};

export default observer(DutyPhoneModal);
