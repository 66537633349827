import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Common/Spinner";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { useHistory } from "react-router-dom";
import GroupHeader from "../../Common/GroupHeader";
import { Pts } from "@models/pts";
import { NEW_PTS } from "@services/constants";
import classNames from "classnames";
import ActiveCell from "../../Common/ActiveCell";
import { Table } from "@frontend/assaia-ui";
import { parseTurnaroundLengths } from "@services/data";

type Props = {
  group: string;
};

const columns = [
  {
    title: "Airline",
    width: "25%",
    render: (v: Pts) => v.airline,
  },
  {
    title: "Description",
    width: "35%",
    render: (v: Pts) => v.description,
  },
  {
    title: "Length",
    width: "35%",
    render: (v: Pts) =>
      parseTurnaroundLengths(v.filters.requiredTurnaroundLength),
  },
  {
    title: "Active",
    width: "5%",
    getCellClass: (v: Pts) => classNames("bold", { green: v.active }),
    render: (v: Pts) => <ActiveCell active={v.active} />,
  },
];

const PtsGroup: React.FC<Props> = ({ group }) => {
  const history = useHistory();
  const { ptsStore } = useContext(HomeContext);
  const {
    ptsList,
    initPtsList,
    togglePtsActive,
    ready,
    setSelectedPtsId,
    deletePtsList,
    copyPtsList,
  } = ptsStore;
  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  const filteredPtsList = ptsList.filter((v) => v.airline === group);

  useEffect(() => {
    initPtsList();
  }, []);

  const openPts = (v: Pts) => {
    history.push(`/pts/${v.id}`);
  };

  const onNewPts = () => {
    setSelectedPtsId(NEW_PTS, group);
  };

  if (!ready) {
    return <Spinner />;
  }

  return (
    <div>
      <GroupHeader
        singularLabel="PTS"
        pluralLabel="PTS"
        group={group}
        activeItems={filteredPtsList
          .filter(({ active }) => active)
          .map(({ id }) => id)}
        selectedItems={selectedNotificationsIds}
        onAddItem={onNewPts}
        onDelete={(ids) => deletePtsList(ids)}
        onCopy={(ids) => copyPtsList(ids)}
        onToggleActive={(val) => togglePtsActive(selectedNotificationsIds, val)}
      />

      <Table
        columns={columns}
        getRowKey={(v: Pts) => v.id}
        items={filteredPtsList}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={openPts}
        getRowClass={(v: Pts) => ""}
      />
    </div>
  );
};

export default observer(PtsGroup);
