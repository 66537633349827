import React, { ComponentProps } from "react";
import {
  ModalLabel,
  ModalHeader,
  ModalCell,
  TertiaryButton,
  ModalDropdownSelectOption,
  ModalDropdownScreen,
  ModalCombobox,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import { ReactComponent as DropdownSVG } from "@assets/dropdown-icon.svg";
import styles from "./style.module.scss";
import {
  parseFlightStatus,
  parseTurnaroundLengths,
  turnLengthsLabels,
  turnStatusesLabels,
} from "@services/data";
import ModalSmallDropdown from "../ModalSmallDropdown";
import {
  FULL_FLIGHTS_ONLY,
  INBOUNDS_ONLY,
  TOW_OFF,
  TOW_ON,
} from "@models/incidentConfig";
import { capitalize } from "lodash";
import { IFiltersStore } from "@stores/FiltersStore/index.types";
import { ModalSpacer } from "../ModalSpacer";

interface Props {
  onClose: () => void;
  onBack: () => void;
  filtersStore: IFiltersStore;
}

const ModalFilterScreen = ({ onClose, onBack, filtersStore }: Props) => {
  const {
    setAircraftsFilterType,
    setStandsFilterType,
    toggleAircraftGroup,
    toggleAircraft,
    toggleStand,
    selectedStands,
    selectedAircraftTypes,
    aircraftFilterType,
    standFilterType,
    aircrafts,
    stands,
    flightStatus,
    turnaroundLength,
    setFlightStatus,
    setTurnaroundLength,
    showStandFilters,
  } = filtersStore;

  const lengthsOptions: ModalDropdownSelectOption<"SHORT" | "LONG">[] = [
    { id: "SHORT", title: parseTurnaroundLengths("SHORT") },
    { id: "LONG", title: parseTurnaroundLengths("LONG") },
  ];

  const statuses: ModalDropdownSelectOption<typeof flightStatus>[] = [
    { id: TOW_ON, title: parseFlightStatus(TOW_ON) },
    { id: TOW_OFF, title: parseFlightStatus(TOW_OFF) },
    { id: INBOUNDS_ONLY, title: parseFlightStatus(INBOUNDS_ONLY) },
    { id: FULL_FLIGHTS_ONLY, title: parseFlightStatus(FULL_FLIGHTS_ONLY) },
  ];

  const aircraftData: ComponentProps<typeof ModalCombobox>["groupedOptions"] =
    {};
  aircrafts.forEach(({ groupName, groupTypes }) => {
    aircraftData[groupName] = groupTypes.map((v) => ({
      id: v,
      title: v,
    }));
  });

  const standsOptions: ComponentProps<typeof ModalCombobox>["options"] =
    stands.map((standId) => ({
      id: standId,
      title: standId,
    }));

  const getSmallDropdown = (
    v: "includes" | "excludes",
    onChange: (v: "includes" | "excludes") => void
  ) => (
    <ModalSmallDropdown
      trigger={
        <TertiaryButton hoverable={true}>
          <span className={styles.text}>{capitalize(v)}</span>
          <div className={styles.icon}>
            <DropdownSVG />
          </div>
        </TertiaryButton>
      }
    >
      <ModalCell
        onClick={() => onChange("includes")}
        className={styles.cell}
        title={"Includes"}
      />
      <ModalCell
        onClick={() => onChange("excludes")}
        className={styles.cell}
        title={"Excludes"}
      />
    </ModalSmallDropdown>
  );

  return (
    <>
      <ModalHeader
        onBack={onBack}
        onClose={onClose}
        icon={<FilterSVG />}
        title={"Filters"}
        showBorderBottom={true}
      />
      <ModalLabel title={"Turnaround length"}>
        <ModalDropdownScreen
          title="Turnaround length"
          value={
            turnaroundLength ? turnLengthsLabels[turnaroundLength] : undefined
          }
          options={lengthsOptions}
          selectedIds={turnaroundLength ? [turnaroundLength] : []}
          emptyOption
          emptyText="Any"
          onSelect={([v]) => setTurnaroundLength(v)}
        />
      </ModalLabel>

      <ModalLabel title={"inbound / outbound"}>
        <ModalDropdownScreen
          title="Inbound / Outbound"
          value={flightStatus ? turnStatusesLabels[flightStatus] : undefined}
          options={statuses}
          selectedIds={flightStatus ? [flightStatus] : []}
          onSelect={([v]) => setFlightStatus(v)}
          emptyOption
        />
      </ModalLabel>

      <ModalLabel
        title={
          <div className={styles.labelDropdown}>
            Aircraft type
            {getSmallDropdown(aircraftFilterType, setAircraftsFilterType)}
          </div>
        }
      >
        <ModalCombobox
          title="Start typing aircraft type..."
          onGroupSelect={toggleAircraftGroup}
          onValueSelect={toggleAircraft}
          selectedIds={selectedAircraftTypes}
          groupedOptions={aircraftData}
        />
      </ModalLabel>

      {showStandFilters && (
        <ModalLabel
          title={
            <div className={styles.labelDropdown}>
              Stand
              {getSmallDropdown(standFilterType, setStandsFilterType)}
            </div>
          }
        >
          <ModalCombobox
            title={"Start typing stand name..."}
            onValueSelect={toggleStand}
            selectedIds={selectedStands}
            options={standsOptions}
          />
        </ModalLabel>
      )}
      <ModalSpacer />
    </>
  );
};

export default observer(ModalFilterScreen);
