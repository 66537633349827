import { createContainer } from "awilix";
import { AdminConfig } from "@frontend/configuration";
import { OpenIdConfig } from "@models/openIdConfig";

export type DIProps = {
  config: AdminConfig;
  openIdConfig: OpenIdConfig;
};

//TODO use more DI

const di = createContainer<DIProps>({ injectionMode: "PROXY" });
// @ts-ignore
window._di = di;

export function getConfig() {
  return di.resolve("config");
}

//TODO review al usages and try to reduce direct usages and use more injections
export default di;
