import User, { UsersResp } from "../models/user";
import { usersApiClient } from "./client";
import Role from "../models/role";
import { UsersApi } from "./types";
import {
  NotificationStatus,
  UserNotificationStatus,
} from "@models/notificationStatus";
import di from "../di/di";

const usersApiUrl = () => {
  const { authority } = di.resolve("openIdConfig");
  return new URL(authority).origin + "/api/";
};

const getUsers = (
  params: Partial<{
    page_number: number;
    page_size: number;
    roles: number[];
    full_name: string;
    email: string;
  }> = {}
): Promise<User[]> => {
  return usersApiClient
    .get<UsersResp>(usersApiUrl() + "users/", {
      params,
    })
    .then((resp) => {
      const { users } = resp.data;
      users.forEach((u) => {
        // @ts-ignore
        delete u.password;
      });
      return users;
    });
};

const updateUser = (user: Partial<User>) => {
  return usersApiClient
    .put<UserNotificationStatus>(usersApiUrl() + `users/${user.id}`, user)
    .then((r) => {
      const u = r.data;
      // @ts-ignore
      delete u.password;
      return u;
    });
};

const resetPassword = (id: number) => {
  return usersApiClient
    .post<NotificationStatus>(usersApiUrl() + `users/${id}/reset_password`)
    .then((r) => r.data);
};

const resetOTPToken = (id: number) => {
  return usersApiClient
    .post<NotificationStatus>(usersApiUrl() + `users/${id}/reset_otp`)
    .then((r) => r.data);
};

const createUser = (user: Partial<User>) => {
  return usersApiClient
    .post<UserNotificationStatus>(usersApiUrl() + `users/`, user)
    .then((r) => {
      const u = r.data;
      // @ts-ignore
      delete u.password;
      return u;
    });
};

const deleteUsers = (ids: number[]) => {
  return Promise.all(
    ids.map((id) =>
      usersApiClient
        .delete<NotificationStatus>(usersApiUrl() + `users/${id}`)
        .then((r) => r.data)
    )
  );
};

const toggleUsersActive = (users: User[]) => {
  return Promise.all(users.map((u) => updateUser(u)));
};

const getRoles = () =>
  usersApiClient.get<Role[]>(usersApiUrl() + "roles/").then((r) => r.data);

const resetToken = (userId: number) =>
  usersApiClient
    .post(usersApiUrl() + `users/${userId}/reset_phone_token`)
    .then((r) => r.data);

const usersApi: UsersApi = {
  getUsers,
  updateUser,
  resetPassword,
  resetOTPToken,
  createUser,
  deleteUsers,
  getRoles,
  resetToken,
  toggleUsersActive,
};

export default usersApi;
