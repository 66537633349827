import React, { useContext, useState } from "react";
import { observer } from "mobx-react";
import {
  Icons24Trash,
  ModalCell,
  ModalHeader,
  ModalIcon,
  ModalLabel,
  ModalSeparator,
  ModalTextArea,
  PrimaryButton,
  Switch,
} from "@frontend/assaia-ui";
import SeverityLevel from "../../Common/SeverityLevel";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import { ReactComponent as TimeSVG } from "@assets/time.svg";
import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import RedStar from "../../Common/RedStar";
import { IncidentModalStoreContext } from "@services/react";
import ModalSmallDropdown from "../../Common/ModalSmallDropdown";
import {
  IncidentAttributeCaption,
  IncidentFilterCaption,
} from "../IncidentAttributeCaption";
import { ModalSpacer } from "../../Common/ModalSpacer";

import styles from "./style.module.scss";

const IncidentMainScreen = () => {
  const {
    setScreen,
    closeModal,
    toggleActive,
    toggleSeverity,
    setCustomText,
    submit,
    isSubmitting,
    customText,
    active,
    severity,
    attributeSettings,
    isNewIncidentConfig,
    trigger,
    getInvalidFields,
    deleteNotification,
  } = useContext(IncidentModalStoreContext);

  const [charCount, setCharCount] = useState(customText.length);

  const buttonDisabled =
    isSubmitting ||
    !!getInvalidFields("attributeSettings", true).length ||
    !!getInvalidFields("startRangeBoundary", true).length ||
    !charCount;

  const attributeSettingsInvalidFields = getInvalidFields("attributeSettings");
  const startRangeBoundaryInvalidFields =
    getInvalidFields("startRangeBoundary");

  return (
    <>
      <ModalHeader
        onClose={closeModal}
        icon={<NotificationSVG />}
        title={isNewIncidentConfig ? "Create new alert" : "Edit alert"}
      >
        {!isNewIncidentConfig && (
          <ModalSmallDropdown
            trigger={
              // FIXME: `onClick` handler's presence makes clickable appearance of the button
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              <ModalIcon onClick={() => {}}>
                <DotsSVG />
              </ModalIcon>
            }
          >
            <ModalCell
              onClick={deleteNotification}
              title={<span className={styles.red}>Delete</span>}
              leftIcon={<Icons24Trash className={styles.red} />}
            />
          </ModalSmallDropdown>
        )}
      </ModalHeader>
      <ModalSeparator title="General settings" />
      <ModalCell title="Active" onClick={toggleActive}>
        <Switch active={active} />
      </ModalCell>
      <ModalCell title="Severity level" onClick={toggleSeverity}>
        <SeverityLevel severity={severity} />
      </ModalCell>
      <ModalSeparator title="Configuration" />
      <ModalCell
        title={<RedStar>Attribute</RedStar>}
        leftIcon={<TriggerSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("attribute")}
      >
        <IncidentAttributeCaption
          error={!!attributeSettingsInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.attributeSettings,
              parseTimeShift: false,
              trigger: trigger,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={<RedStar>Timing</RedStar>}
        leftIcon={<TimeSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("timing")}
      >
        <IncidentAttributeCaption
          error={!!startRangeBoundaryInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.startRangeBoundary,
            },
            {
              attribute: attributeSettings.firingPointInTime,
              required: false,
            },
            {
              attribute: attributeSettings.endRangeBoundary,
              required: false,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        className={styles.filters}
        title={"Filters"}
        leftIcon={<FilterSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setScreen("filters")}
      >
        <IncidentFilterCaption />
      </ModalCell>
      <ModalLabel title={<RedStar>Notification text</RedStar>}>
        <ModalTextArea
          disabled={isSubmitting}
          value={customText}
          placeholder="Set notification text for the alert..."
          onChange={setCustomText}
          maxLength={200}
          onCharCountChange={setCharCount}
        />
      </ModalLabel>
      <ModalSpacer minHeight={"auto"} />
      <PrimaryButton onClick={submit} disabled={buttonDisabled}>
        {isNewIncidentConfig ? "create alert" : "edit alert"}
      </PrimaryButton>
    </>
  );
};

export default observer(IncidentMainScreen);
