import React, { useContext, useMemo } from "react";
import Logo from "../Logo";
import styles from "./style.module.scss";
import userPic from "./user-pic.png";
import { NavLink, Route, Switch } from "react-router-dom";
import UserSearch from "../Search/usersSearch";
import NotificationsSearch from "../Search/notificationsSearch";
import { RootContext } from "@services/react";
import { observer } from "mobx-react";
import Dropdown, { DropdownItem } from "@components/Common/Dropdown";
import { getConfig } from "../../../di/di";

//TODO use react-select or remove react-select
const Header = () => {
  const { user, logout } = useContext(RootContext).authStore;
  const { enableDutyPhones, enablePts } = getConfig();

  const dropdownItems = useMemo<DropdownItem[]>(
    () => [
      {
        title: (
          <>
            Log out <i className="far fa-sign-out"></i>
          </>
        ),
        className: "logout-btn",
        onSelect: logout,
      },
    ],
    []
  );

  const title = useMemo(
    () => (
      <>
        {user && (user.profile.full_name || user.profile.email)}
        <i className="far fa-angle-down" />
        <img src={userPic} />
      </>
    ),
    []
  );

  return (
    <>
      <header className={styles.header}>
        <Logo />
        <Switch>
          <Route path={"/"} exact>
            <UserSearch />
          </Route>
          <Route path={"/notifications"}>
            <NotificationsSearch />
          </Route>
        </Switch>
        <Dropdown
          className={styles.userDropdown}
          title={title}
          items={dropdownItems}
        />
      </header>
      <nav className={styles.nav}>
        <NavLink to={"/"} exact>
          Users
        </NavLink>
        <NavLink to={"/notifications"}>Notifications</NavLink>
        {enableDutyPhones && <NavLink to={"/duty-phones"}>Duty phones</NavLink>}
        {enablePts && <NavLink to={"/pts"}>PTS</NavLink>}
      </nav>
    </>
  );
};
export default observer(Header);
