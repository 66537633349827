import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { find } from "lodash";
import styles from "./style.module.scss";
import {
  getFilterShortInfo,
  getTimerLabelData,
  getTriggerLabelData,
} from "@services/data";
import GroupHeader from "@components/Common/GroupHeader";
import ActiveCell from "@components/Common/ActiveCell";
import SeverityLevel from "@components/Common/SeverityLevel";
import TableColumnContent from "@components/NotificationsPage/TableColumnContent";
import { Table, Popup } from "@frontend/assaia-ui";
import { UnifiedIncidentConfig } from "@models/incidentConfig";
import { HomeContext } from "@services/react";

const columns = [
  {
    title: "Trigger",
    width: "22%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTriggerLabelData(n);
      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: "Start monitoring",
    width: "22%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.startRangeBoundary);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: "Firing point",
    width: "14%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.firingPointInTime);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: "Stop monitoring",
    width: "14%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.endRangeBoundary);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: "Filters",
    width: "7%",
    render: (n: UnifiedIncidentConfig) => {
      const filteredData = getFilterShortInfo(n).filter(([, col2]) => col2);
      const tooltipContent = filteredData.length ? (
        filteredData.map(([col1, col2], index) => {
          return (
            <React.Fragment key={`filter-item-${col1}-${col2}-${index}`}>
              <span>{col1}</span>
              <span>{col2}</span>
            </React.Fragment>
          );
        })
      ) : (
        <span>No filters</span>
      );

      return (
        <Popup
          portalQuery="#root"
          className={styles.tooltipWrapper}
          popupHorizontalAlign={"center"}
          popupVerticalAlign={"top"}
          clickable={false}
          triggerOnHover={true}
          popupContent={
            <div
              className={classNames(styles.tooltip, {
                [styles.noFilters]: filteredData.length === 0,
              })}
            >
              {tooltipContent}
            </div>
          }
        >
          <i className={classNames("fas fa-info-circle")}></i>
        </Popup>
      );
    },
  },
  {
    title: "Severity",
    width: "7%",
    render: (n: UnifiedIncidentConfig) => (
      <span className={styles.severity}>
        <SeverityLevel severity={n.severity} />
      </span>
    ),
  },
  {
    title: "Custom text",
    width: "7%",
    getCellClass: (n: UnifiedIncidentConfig) =>
      classNames("bold", { green: n.customText }),
    render: (n: UnifiedIncidentConfig) => (
      <ActiveCell active={!!n.customText} title={n.customText || ""} />
    ),
  },
  {
    title: "Active",
    width: "7%",
    getCellClass: (n: UnifiedIncidentConfig) =>
      classNames("bold", { green: n.active }),
    render: (n: UnifiedIncidentConfig) => <ActiveCell active={n.active} />,
  },
];

type Props = {
  group: string;
};

const NotificationsGroup = ({ group }: Props) => {
  const {
    notifications: allNotifications,
    prepareNotificationsByGroup,
    searchQuery,
    createNotification,
    deleteNotifications,
    editNotification,
    toggleNotificationsActive,
    highlightItemId,
    setHighlightItem,
  } = useContext(HomeContext).notificationsStore;

  const notifications = prepareNotificationsByGroup(group);

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    setSelectedNotificationsIds([]);
  }, [searchQuery]);

  useEffect(() => {
    const tmpIds = selectedNotificationsIds.filter((id) =>
      find(notifications, { id })
    );
    if (tmpIds.length !== selectedNotificationsIds.length)
      setSelectedNotificationsIds(tmpIds);
  }, [selectedNotificationsIds, allNotifications]);

  const selectedNotifications = notifications.filter((n) =>
    selectedNotificationsIds.includes(n.id)
  );

  return (
    <div className={styles.notificationsGroup}>
      <GroupHeader
        singularLabel="notification"
        pluralLabel="notifications"
        group={group}
        activeItems={selectedNotifications
          .filter(({ active }) => active)
          .map(({ id }) => id)}
        selectedItems={selectedNotifications.map(({ id }) => id)}
        onAddItem={() => createNotification(group)}
        onDelete={() => deleteNotifications(selectedNotificationsIds)}
        onToggleActive={(val) =>
          toggleNotificationsActive(selectedNotificationsIds, val)
        }
      />

      <Table
        columns={columns}
        getRowKey={(n: UnifiedIncidentConfig) => n.id}
        items={notifications.filter((n) => n.group === group)}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={(n: UnifiedIncidentConfig) => editNotification(n)}
        // TODO: It seems to be broken, no css modules
        getRowClass={(n: UnifiedIncidentConfig) => styles.notificationRow}
        highlightItemId={highlightItemId}
        onHighlightEnd={() => setHighlightItem(null)}
      />
    </div>
  );
};

export default observer(NotificationsGroup);
