import React, { useContext } from "react";
import {
  ModalDropdownScreen,
  ModalDropdownSelectOption,
  ModalLabel,
  ModalSeparator,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { capitalize } from "lodash";

import {
  getUniqPropValues,
  parseEventName,
  parseObjectName,
  parseTurnParam,
  snakeToHumanCase,
} from "@services/data";
import AlertTriggerType from "../IncidentTriggerType";
import RedStar from "../../Common/RedStar";
import { IncidentModalStoreContext } from "@services/react";
import { getConfig } from "../../../di/di";

type Props = { sourceTitle: string };

const IncidentParameterSourceSelector = (props: Props) => {
  const { turnaroundEvents: allEvents = [], incidentTurnaroundParams = [] } =
    getConfig();
  const { sourceTitle } = props;
  const {
    selectedAttribute,
    setAttributeSettings,
    getInvalidFields,
    selectedAttributeKey,
  } = useContext(IncidentModalStoreContext);
  const {
    objectName,
    objectType,
    objectPosition,
    eventName,
    type,
    parameterName,
  } = selectedAttribute;
  const invalidFields = getInvalidFields(selectedAttributeKey);

  const objectNames = getUniqPropValues(allEvents, {}, "objectName");
  const nameDropdownItems: ModalDropdownSelectOption[] = objectNames.map(
    (name) => ({
      title: parseObjectName(name),
      id: name,
    })
  );

  const ANY_ID = "**ANY**";
  const anyItem: ModalDropdownSelectOption = {
    title: "Any",
    id: ANY_ID,
  };

  const objectTypes = getUniqPropValues(
    allEvents,
    { objectName },
    "objectType"
  );
  const typeDropdownItems: ModalDropdownSelectOption[] = objectName
    ? objectTypes.map((name) => ({
        title: capitalize(snakeToHumanCase(name)),
        id: name,
      }))
    : [];
  if (typeDropdownItems.length) typeDropdownItems.unshift(anyItem);

  const objectPositions = getUniqPropValues(
    allEvents,
    { objectName, objectType },
    "objectPosition"
  );
  const positionDropdownItems: ModalDropdownSelectOption[] = objectName
    ? objectPositions.map((name) => ({
        title: capitalize(snakeToHumanCase(name)),
        id: name,
      }))
    : [];
  if (positionDropdownItems.length) positionDropdownItems.unshift(anyItem);

  const eventNames = getUniqPropValues(
    allEvents,
    { objectName, objectType, objectPosition },
    "eventType"
  );
  const eventDropdownItems: ModalDropdownSelectOption[] = eventNames.map(
    (name) => ({
      title: parseEventName(name),
      id: name,
    })
  );

  const turnaroundParameterDropdownItems: ModalDropdownSelectOption[] =
    incidentTurnaroundParams.map((p) => ({
      title: parseTurnParam(p),
      id: p,
    }));

  const onAttributePropChanged = (data: Partial<typeof selectedAttribute>) => {
    if (data.objectName) {
      data.objectType = null;
      data.objectPosition = null;
      data.eventName = "";
    } else if (data.objectType) {
      if (data.objectType === ANY_ID) data.objectType = null;
      data.objectPosition = null;
      data.eventName = "";
    } else if (data.objectPosition) {
      if (data.objectPosition === ANY_ID) data.objectPosition = null;
      data.eventName = "";
    }
    setAttributeSettings(data);
  };

  return (
    <>
      <AlertTriggerType
        title="attribute type"
        triggerType={type}
        onTrigger={(v) => setAttributeSettings({ type: v })}
      />
      <ModalSeparator title={sourceTitle} />
      {type === "event" && (
        <>
          <ModalLabel title="Object">
            <ModalDropdownScreen
              error={invalidFields.includes("objectName")}
              placeholder="Select object..."
              options={nameDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectName: v[0] })}
              title={"Object name"}
              selectedIds={[objectName]}
            />
          </ModalLabel>
          <ModalLabel title="Object type">
            <ModalDropdownScreen
              error={invalidFields.includes("objectType")}
              placeholder="Select object type..."
              options={typeDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectType: v[0] })}
              title={"Object type"}
              selectedIds={objectType ? [objectType] : [ANY_ID]}
            />
          </ModalLabel>

          <ModalLabel title="Object position">
            <ModalDropdownScreen
              error={invalidFields.includes("objectPosition")}
              placeholder="Select object opsition..."
              options={positionDropdownItems}
              onSelect={(v) => onAttributePropChanged({ objectPosition: v[0] })}
              title={"Object position"}
              selectedIds={objectPosition ? [objectPosition] : [ANY_ID]}
            />
          </ModalLabel>
          <ModalLabel title="Event">
            <ModalDropdownScreen
              error={invalidFields.includes("objectName")}
              placeholder="Select event..."
              options={eventDropdownItems}
              onSelect={(v) => onAttributePropChanged({ eventName: v[0] })}
              title={"Event"}
              selectedIds={eventName ? [eventName] : []}
            />
          </ModalLabel>
        </>
      )}
      {type === "turnaround_param" && (
        <ModalLabel title={<RedStar>parameters</RedStar>}>
          <ModalDropdownScreen
            title={"Parameters"}
            error={invalidFields.includes("objectName")}
            options={turnaroundParameterDropdownItems}
            selectedIds={[parameterName]}
            onSelect={(v) =>
              setAttributeSettings({
                parameterName: v[0],
              })
            }
          />
        </ModalLabel>
      )}
    </>
  );
};

export default observer(IncidentParameterSourceSelector);
