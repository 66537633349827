import { cloneDeep, difference, first, merge } from "lodash";
import { makeAutoObservable } from "mobx";
import { Pts, PtsSchedule } from "@models/pts";
import { DeepPartial } from "@services/types";
import { IPtsStore } from "../PtsStore/index.types";
import { IPtsScheduleStore } from "./index.types";
import { getConfig } from "../../di/di";

const getSubcategoriesDiffs = (pts: Pts): string[] => {
  const { ptsOperationsGroups } = getConfig();
  const allSubcategories = Object.keys(ptsOperationsGroups);
  const existingSubcategories = pts.schedules.map((v) => v.id);
  return difference(allSubcategories, existingSubcategories);
};

export class PtsScheduleStore implements IPtsScheduleStore {
  static createNewPtsSchedule(pts: Pts): PtsSchedule | null {
    const availableSubcategories = getSubcategoriesDiffs(pts);
    const subcategoryId = first(availableSubcategories);
    if (!subcategoryId) {
      return null;
    }

    return {
      id: subcategoryId,
      direction: "both",
      opName: "",
      start: {
        idealTime: 0,
        referencePoint: "aircraft_start_ts",
        orangeInterval: {
          start: null,
          end: 1 * 60,
        },
        redInterval: {
          start: null,
          end: 2 * 60,
        },
      },
      end: {
        idealTime: 0,
        referencePoint: "sobt",
        orangeInterval: {
          start: null,
          end: 1 * 60,
        },
        redInterval: {
          start: null,
          end: 2 * 60,
        },
      },
      uninterruptible: false,
    };
  }

  operationScreen: "main" | "start" | "end" = "main";
  ptsScheduleData: PtsSchedule;

  constructor(
    public readonly ptsStore: IPtsStore,
    public readonly pts: Pts,
    ptsScheduleData: IPtsScheduleStore["ptsScheduleData"]
  ) {
    this.ptsScheduleData = this._cloneAndConvertSchedule(
      ptsScheduleData,
      "to-minutes"
    );

    makeAutoObservable(this, {}, { autoBind: true });
  }

  setScreen(v: PtsScheduleStore["operationScreen"]) {
    this.operationScreen = v;
  }

  onOperationChange(data: DeepPartial<PtsSchedule>) {
    merge(this.ptsScheduleData, data);
  }

  onSubcategoryChange(id: string) {
    this.onOperationChange({ id });
  }

  async onSubmitOperation() {
    const data = this.ptsScheduleData;
    const pts = cloneDeep(this.pts);

    const converted = this._cloneAndConvertSchedule(data, "to-seconds");
    const index = pts.schedules.findIndex((v) => v.id === converted.id);
    if (index !== -1) {
      pts.schedules[index] = converted;
    } else {
      pts.schedules.push(converted);
    }

    return this.ptsStore.onSubmitPts(pts);
  }

  /**
   * Converts all timestamps in PtsSchedule to seconds or to minutes
   * @returns A new PtsSchedule
   */
  private _cloneAndConvertSchedule(
    data: PtsSchedule,
    mode: "to-minutes" | "to-seconds"
  ): PtsSchedule {
    const multiplier = mode === "to-minutes" ? 1 / 60 : 60;
    const convert = (v: number | null) => (v !== null ? v * multiplier : null);

    const result = cloneDeep(data);
    (["start", "end"] as const).forEach((key) => {
      result[key].idealTime *= multiplier;
      result[key].orangeInterval.start = convert(
        result[key].orangeInterval.start
      );
      result[key].orangeInterval.end = convert(result[key].orangeInterval.end);
      result[key].redInterval.start = convert(result[key].redInterval.start);
      result[key].redInterval.end = convert(result[key].redInterval.end);
    });

    return result;
  }
}
