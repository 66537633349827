import React, { useEffect, useState } from "react";
import Home from "./Home";
import { OIDCApp } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import Spinner from "../Common/Spinner";
import { BrowserRouter } from "react-router-dom";
import rootApi from "../../services/rootApi";
import { OpenIdConfig } from "@models/openIdConfig";
import di from "../../di/di";
import { asValue } from "awilix";
import { ErrorMessage } from "./ErrorMessage";
import { browserHistory } from "@services/router";

const App = () => {
  const [error, setError] = useState<Error | null>(null);
  const [creds, setCreds] = useState<OpenIdConfig | null>(null);

  const onError = (e: Error) => setError(e);

  useEffect(() => {
    rootApi
      .getInitialOpenIdCreds()
      .then((v) => {
        if (process.env.CLIENT_ID) {
          v.client_id = process.env.CLIENT_ID;
        }
        di.register({ openIdConfig: asValue(v) });
        setCreds(v);
      })
      .catch(onError);
  }, []);

  if (error) {
    return <ErrorMessage showRefreshBtn />;
  }

  if (!creds) return <Spinner />;

  return (
    <OIDCApp
      onError={onError}
      authority={creds.authority}
      clientId={creds.client_id}
      onSigninCallback={(restoredValue) => {
        browserHistory.replace({ pathname: restoredValue });
      }}
    >
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </OIDCApp>
  );
};
export default observer(App);
