import React, { Component, ComponentProps, ContextType } from "react";
import { sortBy, xor } from "lodash";
import {
  ModalCell,
  ModalSeparator,
  Switch,
  ModalLabel,
  PrimaryButton,
  ModalIcon,
  Input,
  ModalSpacer,
  Spinner,
  ModalHeader,
  Modal,
  ModalCombobox,
} from "@frontend/assaia-ui";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";

import styles from "./style.module.scss";
import { cloneDeep, merge } from "lodash";
import classNames from "classnames";
import ModalSmallDropdown from "@components/Common/ModalSmallDropdown";
import RedStar from "@components/Common/RedStar";
import { HomeContext } from "@services/react";
import { DeepPartial } from "@services/types";
import { observer } from "mobx-react";
import User, { NewUser } from "@models/user";
import { getConfig } from "../../../di/di";

type Props = {};

type ComponentState = {
  user: User | NewUser;
  emailError: string | null;
  rolesError: string | null;
  phoneError: string | null;
};

class UserModal extends Component<Props, ComponentState> {
  static contextType = HomeContext;
  context!: ContextType<typeof HomeContext>;

  constructor(props: Props, context: any) {
    super(props, context);

    this.context = context;
    this.state = {
      user: cloneDeep(this.usersStore.userModalData as User | NewUser),
      emailError: null,
      rolesError: null,
      phoneError: null,
    };

    console.log(
      `User personal link: id.setup.assaia.com/s/${this.state.user?.token}`
    );
  }

  get personalPhone() {
    return this.state.user?.phone || null;
  }

  get usersStore() {
    return this.context.usersStore;
  }

  edit(data: DeepPartial<User>) {
    this.setState({
      emailError: null,
      rolesError: null,
      user: merge(this.state.user, data),
    });
  }

  setRoles(roles: number[]) {
    const user = { ...this.state.user, roles };
    this.setState({
      user,
      emailError: null,
      rolesError: null,
    });
  }

  save = () => {
    const data = cloneDeep(this.state.user);
    const isNew = !("id" in data);

    if (!data.email) {
      this.setState({ emailError: "Please enter the email address" });
      return;
    }
    if (isNew && !this.validateEmail(data.email)) {
      this.setState({ emailError: "Please enter a valid email address" });
      return;
    }

    if (!this.isPhoneValid(this.personalPhone?.phone || null)) {
      this.setState({ phoneError: "Please enter a valid phone number" });
      return;
    }

    if (isNew && this.usersStore.users.some((u) => u.email === data.email)) {
      this.setState({
        emailError: `User with email ${data.email} aleady exists`,
      });
      return;
    }

    if (!data.roles.length) {
      this.setState({ rolesError: "Please select roles" });
      return;
    }

    this.usersStore.saveUser(data).then(() => this.close());
  };

  delete = () => {
    if (!("id" in this.state.user)) return;
    this.usersStore.deleteUsers([this.state.user.id]).then(() => this.close());
  };

  resetPassword = () => {
    if (!("id" in this.state.user)) return;
    this.usersStore
      .resetPassword([this.state.user.id])
      .then(() => this.close());
  };

  resetOTPToken = () => {
    if (!("id" in this.state.user)) return;
    this.usersStore
      .resetOTPToken([this.state.user.id])
      .then(() => this.close());
  };

  validateEmail = (email: string) => {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isPhoneValid(phone: string | null) {
    if (!phone) {
      return true;
    }

    return !phone.includes("_");
  }

  sendPersonalLink() {
    const { userModalData: user, sendPersonalLink } = this.usersStore;
    if (user && "id" in user) sendPersonalLink(user.id);
  }

  onPhoneChange = (value: string) => {
    const { userModalData: user } = this.usersStore;
    this.edit({
      phone: { phone: value, active: user?.phone?.active || true },
    });
  };

  close = () => {
    this.usersStore.editUser();
  };

  render() {
    const {
      enableOTPTokenReset,
      enableResendUserPersonalLink,
      enableDutyPhones,
      phonesMask,
    } = getConfig();
    const { user, emailError, phoneError, rolesError } = this.state;
    const { roles, loading } = this.usersStore;

    const isNew = !("id" in user);

    const title = isNew ? "New user" : user.profile.full_name || user.email;
    const sendLinkEnabled =
      this.personalPhone !== null &&
      this.isPhoneValid(this.personalPhone.phone);

    let rolesOptions: ComponentProps<typeof ModalCombobox>["options"] =
      roles.map((r) => ({
        title: r.description,
        id: r.id.toString(),
      }));
    rolesOptions = sortBy(rolesOptions, (v) => v.title.toLowerCase());

    return (
      <>
        <Modal onClose={this.close}>
          {loading && (
            <div className={styles.userModalOverlay}>
              <Spinner className={styles.userModalLoader} />
            </div>
          )}
          <ModalHeader title={title} onClose={this.close}>
            {!isNew && (
              <ModalSmallDropdown
                trigger={
                  <ModalIcon onClick={() => {}}>
                    <DotsSVG />
                  </ModalIcon>
                }
              >
                <ModalCell
                  onClick={this.delete}
                  title={<span className={styles.red}>Delete</span>}
                />
                <ModalCell
                  onClick={this.resetPassword}
                  title={<span>Reset password</span>}
                />
                {enableResendUserPersonalLink && (
                  <ModalCell
                    className={classNames({
                      disabled: !sendLinkEnabled,
                    })}
                    onClick={() => sendLinkEnabled && this.sendPersonalLink()}
                    title={<span>Resend personal link</span>}
                  />
                )}
                {enableOTPTokenReset && (
                  <ModalCell
                    onClick={this.resetOTPToken}
                    title={<span>Reset OTP token</span>}
                  />
                )}
              </ModalSmallDropdown>
            )}
          </ModalHeader>
          <ModalCell
            title="Active"
            onClick={() => this.edit({ active: !user.active })}
          >
            <Switch active={user.active}></Switch>
          </ModalCell>

          <ModalSeparator title="User details" />
          <ModalLabel title={<RedStar>Email</RedStar>}>
            <Input
              type="email"
              value={user.email}
              disabled={"id" in user}
              name={"email"}
              placeholder={"Enter email"}
              onChange={(email) => this.edit({ email })}
            />
          </ModalLabel>
          {emailError && <span className={styles.error}>{emailError}</span>}
          <ModalLabel title="Full name">
            <Input
              trim={false}
              value={user.profile.full_name || ""}
              name={"name"}
              placeholder={"Enter full name"}
              onChange={(full_name) => this.edit({ profile: { full_name } })}
            />
          </ModalLabel>
          <ModalLabel title={"Company"}>
            <Input
              trim={false}
              value={user.profile.company || ""}
              name={"company"}
              placeholder={"Enter company"}
              onChange={(company) => this.edit({ profile: { company } })}
            />
          </ModalLabel>
          {enableDutyPhones && (
            <>
              <ModalSeparator
                title="Personal phone"
                className={styles.phoneSection}
              />
              <ModalCell title="Phone" hoverable={false}>
                <Input
                  mask={phonesMask}
                  value={user?.phone?.phone || ""}
                  onChange={this.onPhoneChange}
                  onClear={() => this.edit({ phone: null })}
                />
              </ModalCell>
              {phoneError && <span className={styles.error}>{phoneError}</span>}
              <ModalCell
                title="Active"
                hoverable={!!this.state.user.phone}
                onClick={() =>
                  this.state.user.phone &&
                  this.edit({ phone: { active: !user.phone?.active } })
                }
              >
                <Switch
                  active={user.phone ? user.phone.active : true}
                  disabled={!this.state.user.phone}
                />
              </ModalCell>
            </>
          )}
          <ModalLabel
            title={<RedStar>Roles</RedStar>}
            className={styles.rolesSection}
          >
            <ModalCombobox
              className={styles.userModal}
              title={"Start typing role..."}
              onValueSelect={(v) => this.setRoles(xor(user.roles, [+v]))}
              selectedIds={user.roles.map((v) => v.toString())}
              options={rolesOptions}
            />
            <span className={"error"}>{rolesError}</span>
          </ModalLabel>

          <ModalSpacer />

          <PrimaryButton onClick={() => !loading && this.save()}>
            Save
          </PrimaryButton>
        </Modal>
      </>
    );
  }
}

export default observer(UserModal);
