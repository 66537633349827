import React, { useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import UsersPage from "../../UsersPage";
import Header from "../Header";
import "./style.scss";
import UIAlertModal from "../UIAlertModal";
import NotificationsPage from "../../NotificationsPage";
import { USERS_ROUTE } from "@services/router";
import DutyPhonesPage from "../../DutyPhonesPage";
import { observer } from "mobx-react";
import { HomeContext, RootContext } from "@services/react";
import PtsDetailsPage from "../../PtsDetailsPage";
import PtsListPage from "../../PtsListPage";
import PtsMainModal from "../../PtsMainModal";
import { OIDCContext } from "@frontend/assaia-ui";
import Spinner from "../../Common/Spinner";
import { getConfig } from "../../../di/di";

const Home = () => {
  const { user, logout } = useContext(OIDCContext);
  const {
    initHomeStore,
    disposeHomeStore,
    authStore: { setUser, setLogoutCallback },
  } = useContext(RootContext);

  const homeStore = useMemo(() => {
    setLogoutCallback(logout);
    setUser(user);
    return initHomeStore();
  }, [initHomeStore]);

  useEffect(() => {
    return () => disposeHomeStore();
  }, []);

  const {
    ptsStore: { ptsData },
    uiAlert: notification,
    ready,
  } = homeStore;

  if (!ready) {
    return <Spinner />;
  }

  const { enableDutyPhones, enablePts } = getConfig();
  return (
    <HomeContext.Provider value={homeStore}>
      <div className={"home"}>
        <Header />
        <Switch>
          <Route
            path={[`/${USERS_ROUTE}/:userId?`, "/"]}
            component={UsersPage}
            exact
          />
          <Route path={"/notifications"} component={NotificationsPage} exact />
          {enableDutyPhones && (
            <Route path={"/duty-phones"} component={DutyPhonesPage} exact />
          )}
          {enablePts && <Route path={"/pts"} component={PtsListPage} exact />}
          <Route path={"/pts/:ptsId"} component={PtsDetailsPage} exact />
          <Redirect to={"/"} />
        </Switch>
        {notification && <UIAlertModal />}
        {ptsData && <PtsMainModal ptsData={ptsData} />}
      </div>
    </HomeContext.Provider>
  );
};
export default observer(Home);
