import {
  Icons24Trash,
  Icons24AddUser,
  SecondaryButton,
} from "@frontend/assaia-ui";
import styles from "./style.module.scss";
import { HomeContext } from "@services/react";
import { observer } from "mobx-react";
import { useContext } from "react";
import ExportButton from "@components/Common/ExportButton";
import { getConfig } from "../../../di/di";

const UsersMenu = () => {
  const { enableOTPTokenReset } = getConfig();
  const { usersStore } = useContext(HomeContext);
  const {
    toggleUsersActive,
    deleteUsers,
    setSelectedUsers,
    resetPassword,
    resetOTPToken,
    exportUsers,
    createUser,
    usersMap,
    exportingUsers,
    selectedUsersIds: selectedIds,
  } = usersStore;

  const onToggleActive = (val: boolean) => {
    const ids = selectedIds.filter((id) => usersMap[id]?.active !== val);
    toggleUsersActive(ids, val);
  };

  const canActivate = selectedIds.some((id) => !usersMap[id]?.active);
  const canDeactivate = selectedIds.some((id) => usersMap[id]?.active);

  return (
    <div className={styles.usersMenu}>
      {!!selectedIds.length && (
        <>
          <SecondaryButton
            colorTheme="dark"
            onClick={() => setSelectedUsers([])}
          >
            Clear selection
          </SecondaryButton>
          {canActivate && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => onToggleActive(true)}
            >
              Activate
            </SecondaryButton>
          )}
          {canDeactivate && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => onToggleActive(false)}
            >
              Deactivate
            </SecondaryButton>
          )}
          <SecondaryButton
            colorTheme="dark"
            onClick={() => resetPassword(selectedIds)}
          >
            Reset password
          </SecondaryButton>
          {enableOTPTokenReset && (
            <SecondaryButton
              colorTheme="dark"
              onClick={() => resetOTPToken(selectedIds)}
            >
              Reset OTP token
            </SecondaryButton>
          )}
          <SecondaryButton
            colorTheme="dark"
            onClick={() => deleteUsers(selectedIds)}
            className={styles.red}
            leftIcon={<Icons24Trash />}
          >
            Delete
          </SecondaryButton>
        </>
      )}
      {!selectedIds.length && (
        <SecondaryButton
          colorTheme="dark"
          onClick={createUser}
          leftIcon={<Icons24AddUser />}
        >
          Add user
        </SecondaryButton>
      )}
      <ExportButton onClick={exportUsers} exporting={exportingUsers} />
    </div>
  );
};

export default observer(UsersMenu);
