import React from "react";
import { observer } from "mobx-react";
import styles from "./style.module.scss";

interface Props {
  subtitle?: React.ReactNode;
}

const TableColumnContent: React.FC<Props> = ({ children, subtitle }) => {
  return (
    <div className={styles.columnContent}>
      <span className={styles.title}>{children}</span>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
};

export default observer(TableColumnContent);
