import s from "./style.module.scss";
import { observer } from "mobx-react";
import { PrimaryButton, Modal, ModalHeader } from "@frontend/assaia-ui";

type Props = {
  text: string;
  onNo: () => void;
  onYes: () => void;
};
const YesNoModal = ({ text, onNo, onYes }: Props) => {
  return (
    <Modal
      className={s.yesNoModal}
      title={text}
      onClose={onNo}
      containerClassName={s.backdrop}
    >
      <ModalHeader title={text} onClose={onNo} />
      <div className={s.buttons}>
        <PrimaryButton onClick={onNo} className={s.danger}>
          No
        </PrimaryButton>
        <PrimaryButton onClick={onYes}>Yes</PrimaryButton>
      </div>
    </Modal>
  );
};

export default observer(YesNoModal);
