import { createContext } from "react";
import { IHomeStore } from "@stores/HomeStore/index.types";
import { IPtsScheduleStore } from "@stores/PtsScheduleStore/index.types";
import { IRootStore } from "@stores/RootStore/index.types";
import { IIncidentModalStore } from "@stores/IncidentModalStore/index.types";

export const RootContext = createContext<IRootStore>({} as IRootStore);

export const HomeContext = createContext<IHomeStore>({} as IHomeStore);

export const PtsScheduleContext = createContext<IPtsScheduleStore>(
  {} as IPtsScheduleStore
);

export const IncidentModalStoreContext = createContext<IIncidentModalStore>(
  {} as IIncidentModalStore
);
