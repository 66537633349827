import { makeAutoObservable } from "mobx";
import AuthStore from "../AuthStore";
import { HomeStore } from "../HomeStore";
import { IHomeStore } from "../HomeStore/index.types";
import { IAuthStore } from "../AuthStore/index.types";
import { IRootStore } from "./index.types";

//TODO root store seems useless
export class RootStore implements IRootStore {
  authStore: IAuthStore;
  homeStore: IHomeStore | null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.authStore = new AuthStore(this);
  }

  initHomeStore(): IHomeStore {
    const homeStore = new HomeStore(this);
    this.setHomeStore(homeStore);

    return homeStore;
  }

  disposeHomeStore() {
    this.setHomeStore(null);
  }

  setHomeStore(homeStore: RootStore["homeStore"]) {
    this.homeStore = homeStore;
  }
}

export const rootStore = new RootStore();

// @ts-ignore
window._rootStore = rootStore;
