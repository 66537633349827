import React, { useContext } from "react";
import "./style.scss";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";

const NotificationsSearch = () => {
  const { searchQuery, setNotificationsQuery } =
    useContext(HomeContext).notificationsStore;

  return (
    <div className={"search"}>
      <input
        placeholder={"Search notifications (min 2 symbols)"}
        value={searchQuery}
        onChange={(ev) => setNotificationsQuery(ev.target.value)}
      />
      <i className={"fas fa-search"} />
    </div>
  );
};
export default observer(NotificationsSearch);
