import React, { useContext, useEffect } from "react";
import {
  ModalLabel,
  ModalHelp,
  ModalHeader,
  ModalDropdownScreen,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import { getTitleByOptions, triggerKeysOptions } from "@services/data";
import TurnaroundAttributeSelector from "../TurnaroundAttributeSelector";
import { IncidentModalStoreContext } from "@services/react";
import { ModalSpacer } from "../../Common/ModalSpacer";

const IncidentAttributeScreen = () => {
  const {
    closeModal,
    setScreen,
    trigger,
    setTrigger,
    getInvalidFields,
    selectedAttributeKey,
    markScreenAsShown,
  } = useContext(IncidentModalStoreContext);

  useEffect(() => () => markScreenAsShown(selectedAttributeKey), []);

  const triggerTitle = getTitleByOptions(triggerKeysOptions, trigger);

  const invalidFields = getInvalidFields(selectedAttributeKey);

  return (
    <>
      <ModalHeader
        onBack={() => setScreen("main")}
        onClose={closeModal}
        icon={<TriggerSVG />}
        title="Attribute settings"
      />
      <TurnaroundAttributeSelector sourceTitle={"attribute configuration"} />
      <ModalLabel title="action trigger">
        <ModalDropdownScreen
          error={invalidFields.includes("parameterName")}
          placeholder="Select the trigger..."
          title="Action trigger"
          value={triggerTitle}
          options={triggerKeysOptions}
          selectedIds={[trigger]}
          onSelect={([v]) => setTrigger(v)}
        />
      </ModalLabel>
      <ModalSpacer minHeight={"8px"} />
      <ModalHelp>Attribute settings define the actions</ModalHelp>
    </>
  );
};

export default observer(IncidentAttributeScreen);
