import React, { PropsWithChildren } from "react";
import s from "./style.module.scss";

const RedStar = ({ children }: PropsWithChildren<{}>) => (
  <span>
    {children}&nbsp;
    <span className={s.star}>*</span>
  </span>
);

export default RedStar;
