import ReactDOM from "react-dom";
import YesNoModal from "../components/Common/YesNoModal";

export async function confirmModal(text: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const container = document.getElementById("confirm-modal-container");
    if (!container) {
      reject(new Error("Container for confirm modal is not found!"));
      return;
    }

    const onNo = () => {
      ReactDOM.unmountComponentAtNode(container);
      resolve(false);
    };

    const onYes = () => {
      ReactDOM.unmountComponentAtNode(container);
      resolve(true);
    };

    ReactDOM.render(
      <YesNoModal text={text} onNo={onNo} onYes={onYes} />,
      container
    );
  });
}
