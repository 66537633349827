import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import {
  ModalLabel,
  Chip,
  Input,
  ModalIcon,
  ModalCell,
  ModalHeader,
} from "@frontend/assaia-ui";
import RedStar from "../../Common/RedStar";
import { ReactComponent as TriggerSVG } from "@assets/trigger.svg";
import { ReactComponent as ResetSVG } from "@assets/reset.svg";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import { IncidentModalStoreContext } from "@services/react";
import ModalSmallDropdown from "../../Common/ModalSmallDropdown";
import TurnaroundAttributeSelector from "../TurnaroundAttributeSelector";

import { ModalSpacer } from "../../Common/ModalSpacer";
import styles from "./style.module.scss";

const titleMap = {
  startRangeBoundary: "Start monitoring",
  firingPointInTime: "Firing point",
  endRangeBoundary: "Stop monitoring",
  attributeSettings: "attributeSettings;",
} as const;

const IncidentTimingScreenSettings = () => {
  const {
    timeShift,
    closeModal,
    setScreen,
    selectedAttributeKey,
    resetAttributeSettings,
    setTimingTimeShift,
    markScreenAsShown,
  } = useContext(IncidentModalStoreContext);

  useEffect(() => () => markScreenAsShown(selectedAttributeKey), []);

  return (
    <>
      <ModalHeader
        onBack={() => setScreen("timing")}
        onClose={closeModal}
        icon={<TriggerSVG />}
        title={titleMap[selectedAttributeKey]}
      >
        <ModalSmallDropdown
          trigger={
            <ModalIcon onClick={() => void 0}>
              <DotsSVG />
            </ModalIcon>
          }
        >
          <ModalCell
            onClick={resetAttributeSettings}
            title={<span>Reset</span>}
            leftIcon={<ResetSVG />}
          />
        </ModalSmallDropdown>
      </ModalHeader>
      <TurnaroundAttributeSelector sourceTitle={"start configuration"} />
      <ModalLabel title={<RedStar>Time shift</RedStar>}>
        <Input
          value={`${timeShift}`}
          onChange={setTimingTimeShift}
          suffix="Min"
        />
      </ModalLabel>
      <ModalLabel className={styles.caption} title="Popular time shifts">
        <div className={styles.buttons}>
          <Chip onClick={() => setTimingTimeShift(-5)}>-5m</Chip>
          <Chip onClick={() => setTimingTimeShift(5)}>5m</Chip>
          <Chip onClick={() => setTimingTimeShift(10)}>10m</Chip>
        </div>
      </ModalLabel>
      <ModalSpacer />
    </>
  );
};

export default observer(IncidentTimingScreenSettings);
