import { makeAutoObservable } from "mobx";
import { difference, union } from "lodash";
import { getSelectedTypes, getStatusValue } from "@services/data";
import {
  IncidentConfig,
  TOW_OFF,
  SET,
  EMPTY,
  TOW_ON,
  INBOUNDS_ONLY,
  FULL_FLIGHTS_ONLY,
} from "@models/incidentConfig";
import { IHomeStore } from "../HomeStore/index.types";
import { IFiltersStore } from "./index.types";

export class FiltersStore implements IFiltersStore {
  aircraftFilterType: "includes" | "excludes" = "includes";
  selectedAircraftTypes: string[] = [];

  standFilterType: "includes" | "excludes" = "includes";
  selectedStands: string[] = [];

  inboundFlightStatus: IncidentConfig["inboundFlightStatus"] = null;
  outboundFlightStatus: IncidentConfig["outboundFlightStatus"] = null;
  requiredTurnaroundLength: IncidentConfig["requiredTurnaroundLength"] = null;

  showStandFilters = false;

  constructor(
    private _home: IHomeStore,
    data: {
      requiredAircraftTypes: string[];
      excludedAircraftTypes: string[];
      requiredStands?: string[];
      excludedStands?: string[];
      inboundFlightStatus: FiltersStore["inboundFlightStatus"];
      outboundFlightStatus: FiltersStore["outboundFlightStatus"];
      requiredTurnaroundLength: FiltersStore["requiredTurnaroundLength"];
    },
    options: {
      showStandFilters: boolean;
    }
  ) {
    const {
      requiredAircraftTypes,
      excludedAircraftTypes,
      requiredStands = [],
      excludedStands = [],
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
    } = data;
    const aircraftsFilter = getSelectedTypes(
      requiredAircraftTypes,
      excludedAircraftTypes
    );
    this.aircraftFilterType = aircraftsFilter.filterType;
    this.selectedAircraftTypes = aircraftsFilter.selectedTypes;

    const standsFilter = getSelectedTypes(requiredStands, excludedStands);
    this.standFilterType = standsFilter.filterType;
    this.selectedStands = standsFilter.selectedTypes;

    this.inboundFlightStatus = inboundFlightStatus;
    this.outboundFlightStatus = outboundFlightStatus;
    this.requiredTurnaroundLength = requiredTurnaroundLength;

    this.showStandFilters = options.showStandFilters;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get aircrafts() {
    return this._home.aircrafts;
  }

  get stands() {
    return this._home.notificationsStore.stands;
  }

  get flightStatus() {
    const { inboundFlightStatus, outboundFlightStatus } = this;
    return getStatusValue({ inboundFlightStatus, outboundFlightStatus });
  }

  get turnaroundLength() {
    return this.requiredTurnaroundLength;
  }

  get aircraftFilters(): {
    requiredAircraftTypes: string[];
    excludedAircraftTypes: string[];
  } {
    const { aircraftFilterType, selectedAircraftTypes } = this;
    if (aircraftFilterType === "includes") {
      return {
        requiredAircraftTypes: [...selectedAircraftTypes],
        excludedAircraftTypes: [],
      };
    } else {
      return {
        requiredAircraftTypes: [],
        excludedAircraftTypes: [...selectedAircraftTypes],
      };
    }
  }

  get standFilters(): {
    requiredStands: string[];
    excludedStands: string[];
  } {
    const { standFilterType, selectedStands } = this;
    if (standFilterType === "includes") {
      return {
        requiredStands: [...selectedStands],
        excludedStands: [],
      };
    } else {
      return {
        requiredStands: [],
        excludedStands: [...selectedStands],
      };
    }
  }

  toggleAircraftGroup(aircraftGroup: string) {
    const foundGroup = this._home.aircrafts.find(
      (v) => v.groupName === aircraftGroup
    );

    if (!foundGroup) {
      return;
    }

    const { groupTypes } = foundGroup;
    const everySelected = groupTypes.every((v) =>
      this.selectedAircraftTypes.includes(v)
    );

    if (everySelected) {
      this.selectedAircraftTypes = difference(
        this.selectedAircraftTypes,
        groupTypes
      );
    } else {
      this.selectedAircraftTypes = union(
        this.selectedAircraftTypes,
        groupTypes
      );
    }
  }

  toggleAircraft(v: string) {
    const aircraftExists = this._home.aircrafts.some((group) =>
      group.groupTypes.includes(v)
    );

    if (!aircraftExists) {
      return;
    }

    if (this.selectedAircraftTypes.includes(v)) {
      this.selectedAircraftTypes = difference(this.selectedAircraftTypes, [v]);
      return;
    }

    this.selectedAircraftTypes.push(v);
  }

  toggleStand(v: string) {
    const standExists = this.stands.includes(v);
    if (!standExists) {
      return;
    }

    if (this.selectedStands.includes(v)) {
      this.selectedStands = difference(this.selectedStands, [v]);
      return;
    }

    this.selectedStands.push(v);
  }

  setAircraftsFilterType(v: FiltersStore["aircraftFilterType"]) {
    this.aircraftFilterType = v;
  }

  setStandsFilterType(v: FiltersStore["standFilterType"]) {
    this.standFilterType = v;
  }

  setFlightStatus(v?: string) {
    let inboundFlightStatus: IncidentConfig["inboundFlightStatus"] = null;
    let outboundFlightStatus: IncidentConfig["outboundFlightStatus"] = null;

    if (v === TOW_OFF) {
      inboundFlightStatus = SET;
      outboundFlightStatus = EMPTY;
    } else if (v === TOW_ON) {
      inboundFlightStatus = EMPTY;
      outboundFlightStatus = SET;
    } else if (v === INBOUNDS_ONLY) {
      inboundFlightStatus = SET;
      outboundFlightStatus = null;
    } else if (v === FULL_FLIGHTS_ONLY) {
      inboundFlightStatus = SET;
      outboundFlightStatus = SET;
    }

    this.inboundFlightStatus = inboundFlightStatus;
    this.outboundFlightStatus = outboundFlightStatus;
  }

  setTurnaroundLength(v?: FiltersStore["turnaroundLength"]) {
    this.requiredTurnaroundLength = v || null;
  }
}
