import React, { useContext } from "react";
import { observer } from "mobx-react";
import { ModalHeader, ModalCell } from "@frontend/assaia-ui";

import { ReactComponent as TimeSVG } from "@assets/time.svg";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { IncidentModalStoreContext } from "@services/react";
import RedStar from "../../Common/RedStar";
import { IncidentAttributeCaption } from "../IncidentAttributeCaption";
import { ModalSpacer } from "@components/Common/ModalSpacer";

const IncidentTimingScreen = () => {
  const {
    closeModal,
    setScreen,
    setSelectedAttribute,
    attributeSettings,
    getInvalidFields,
  } = useContext(IncidentModalStoreContext);

  const startRangeBoundaryInvalidFields =
    getInvalidFields("startRangeBoundary");

  return (
    <>
      <ModalHeader
        showBorderBottom={true}
        onBack={() => setScreen("main")}
        onClose={closeModal}
        icon={<TimeSVG />}
        title="Timing"
      />
      <ModalCell
        title={<RedStar>Start monitoring</RedStar>}
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("startRangeBoundary");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          error={!!startRangeBoundaryInvalidFields.length}
          data={[
            {
              attribute: attributeSettings.startRangeBoundary,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={"Firing point"}
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("firingPointInTime");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          data={[
            {
              attribute: attributeSettings.firingPointInTime,
            },
          ]}
        />
      </ModalCell>
      <ModalCell
        title={"Stop monitoring"}
        rightIcon={<NavRightSVG />}
        onClick={() => {
          setSelectedAttribute("endRangeBoundary");
          setScreen("timingOptions");
        }}
      >
        <IncidentAttributeCaption
          data={[
            {
              attribute: attributeSettings.endRangeBoundary,
            },
          ]}
        />
      </ModalCell>
      <ModalSpacer />
    </>
  );
};

export default observer(IncidentTimingScreen);
