import React, { PropsWithChildren } from "react";
import { observer } from "mobx-react";
import { ModalCell, ModalSeparator, Radio } from "@frontend/assaia-ui";

import { MergedTurnaroundAttributeType } from "@models/incidentConfig";
import { getConfig } from "../../../di/di";

type Props = PropsWithChildren<{
  title: string;
  triggerType: MergedTurnaroundAttributeType;
  onTrigger: (v: MergedTurnaroundAttributeType) => void;
}>;

const IncidentTriggerType = ({
  title,
  triggerType,
  onTrigger,
  children,
}: Props) => {
  const { texts } = getConfig();

  return (
    <>
      <ModalSeparator title={title} />
      <ModalCell
        title="Event"
        subtitle="Actions during the turnaround"
        onClick={() => onTrigger("event")}
        leftIcon={<Radio active={triggerType === "event"} />}
      />
      <ModalCell
        title="Time parameter"
        subtitle={texts.flight_parameter_subtitle}
        onClick={() => onTrigger("turnaround_param")}
        leftIcon={<Radio active={triggerType === "turnaround_param"} />}
      />
      {children}
    </>
  );
};

export default observer(IncidentTriggerType);
