import React, { useContext, useEffect, useMemo } from "react";
import "./style.scss";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { debounce } from "lodash";

const SEARCH_QUERY_TIMEOUT = 600; // ms

const UserSearch = () => {
  const { searchQuery, setUserQuery, searchUsers } =
    useContext(HomeContext).usersStore;

  const debouncedSearchUsers = useMemo(
    () => debounce(searchUsers, SEARCH_QUERY_TIMEOUT),
    []
  );

  useEffect(() => debouncedSearchUsers.cancel, []);

  return (
    <div className={"search"}>
      <input
        placeholder={"Search users (min 2 symbols)"}
        value={searchQuery}
        onChange={(ev) => {
          const newQuery = ev.target.value;
          debouncedSearchUsers(searchQuery, newQuery);
          setUserQuery(newQuery);
        }}
      />
      <i className={"fas fa-search"} />
    </div>
  );
};
export default observer(UserSearch);
