import React, { useContext } from "react";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";

import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import {
  ModalCell,
  Switch,
  PrimaryButton,
  ModalSpacer,
  ModalLabel,
  ModalTextArea,
  ModalHeader,
} from "@frontend/assaia-ui";
import { IFiltersStore } from "@stores/FiltersStore/index.types";

const PtsMainModalScreen: React.FC<{ filtersStore: IFiltersStore }> = ({
  filtersStore,
}) => {
  const history = useHistory();
  const { ptsStore } = useContext(HomeContext);
  const {
    ptsData: pts,
    onPtsChange,
    setSelectedPtsId,
    onSubmitPts,
    setPtsMainModalScreen,
    closeModal,
  } = ptsStore;

  if (!pts) {
    return null;
  }

  const { active, description } = pts;

  const submitPts = async () => {
    const {
      aircraftFilters: { requiredAircraftTypes, excludedAircraftTypes },
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
    } = filtersStore;
    const newPts = await onSubmitPts({
      ...pts,
      filters: {
        requiredAircraftTypes,
        excludedAircraftTypes,
        inboundFlightStatus,
        outboundFlightStatus,
        requiredTurnaroundLength,
      },
    });

    if (newPts && !("id" in pts)) {
      setSelectedPtsId(newPts.id);
      history.push(`/pts/${newPts.id}`);
    }
    closeModal();
  };

  return (
    <>
      <ModalHeader
        onClose={closeModal}
        title="PTS Settings"
        showBorderBottom={false}
      ></ModalHeader>

      <ModalCell
        title="Enabled"
        onClick={() => onPtsChange({ active: !active })}
        leftIcon={<NotificationSVG />}
      >
        <Switch active={active}></Switch>
      </ModalCell>

      <ModalCell
        title={"Filters"}
        leftIcon={<FilterSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setPtsMainModalScreen("filters")}
      ></ModalCell>

      <ModalLabel title={"Description"}>
        <ModalTextArea
          value={description}
          placeholder="Set description text for the PTS..."
          onChange={(v) =>
            onPtsChange({
              description: v,
            })
          }
          maxLength={200}
        />
      </ModalLabel>

      <ModalSpacer />

      <PrimaryButton disabled={false} onClick={submitPts}>
        Save
      </PrimaryButton>
    </>
  );
};

export default observer(PtsMainModalScreen);
