import React, { ReactElement } from "react";
import classnames from "classnames";
import "./style.scss";

export interface DropdownItem<T = any> {
  title: ReactElement<any, any> | string;
  queryString?: string;
  key?: string | number;
  onSelect?: () => Promise<void> | void | boolean;
  className?: string;
  data?: T;
}

export type Props = {
  title: ReactElement<any, any> | string;
  onOpen?: () => void;
  onClose?: () => void;
  selectedItem?: DropdownItem;
  items: DropdownItem[];
  onSelect?: (i: DropdownItem) => any | boolean;
  onItemHighlighted?: (i: DropdownItem | null) => void;
  align?: string;
  className?: string;
  openCode?: string;
  maxHeight?: number;
};

type State = {
  opened: boolean;
  highlightedItem: DropdownItem | null;
  maxListHeight: number;
  shiftCoods: [number, number];
};

export default class Dropdown extends React.Component<Props, State> {
  list = React.createRef<HTMLDivElement>();
  container = React.createRef<HTMLDivElement>();
  triggerEventTimestamp = 0;
  prevOpened = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      opened: false,
      highlightedItem: null,
      maxListHeight: 0,
      shiftCoods: [0, 0],
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onDocumentClick);
  }

  componentDidUpdate() {
    if (this.prevOpened !== this.state.opened) {
      this.checkVisibility();
      this.prevOpened = this.state.opened;
    }
  }

  onDocumentClick(ev: MouseEvent) {
    if (ev.timeStamp !== this.triggerEventTimestamp) this.close();
  }

  down() {
    const { highlightedItem } = this.state;
    const { items } = this.props;
    if (!highlightedItem) return;
    const index = items.indexOf(highlightedItem);
    if (index === items.length - 1) return;
    this.highlight(items[index + 1]);
  }

  up() {
    const { highlightedItem } = this.state;
    const { items } = this.props;
    if (!highlightedItem) return;
    const index = items.indexOf(highlightedItem);
    if (index === 0) return;
    this.highlight(items[index - 1]);
  }

  open(ev?: React.MouseEvent) {
    if (ev) this.triggerEventTimestamp = ev.timeStamp;

    if (this.container.current) {
      const rect = this.container.current.getBoundingClientRect();
      this.setState({
        maxListHeight: window.innerHeight - 20 - rect.bottom,
        shiftCoods: [0, 0],
      });
    }

    this.setState({ opened: true });
    this.highlight(this.props.items[0]);
    document.addEventListener("click", this.onDocumentClick);

    if (this.props.onOpen) this.props.onOpen();
  }

  close() {
    document.removeEventListener("click", this.onDocumentClick);
    this.setState({ opened: false });
    this.highlight(null);
    if (this.props.onClose) this.props.onClose();
  }

  select(item: DropdownItem, ev?: React.MouseEvent) {
    let close: boolean | undefined = true;
    if (item.onSelect) close = item.onSelect() !== false;
    else close = this.props.onSelect && this.props.onSelect(item) !== false;

    if (close) this.close();
    else if (ev) {
      ev.nativeEvent.stopImmediatePropagation();
    }
  }

  highlight(item: DropdownItem | null) {
    this.setState({ highlightedItem: item });
    if (this.props.onItemHighlighted) this.props.onItemHighlighted(item);
  }

  initListHotkeys() {
    const list = this.list.current;
    if (!list) return;

    list.focus();
    //react-key-handler listens document and cannot be canceled on document level
    list.addEventListener("keydown", this.onListKeyDown.bind(this));
    list.addEventListener("keyup", (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
    });
  }

  onListKeyDown(ev: KeyboardEvent) {
    ev.stopPropagation();
    ev.preventDefault();

    switch (ev.code) {
      case "Escape":
        this.close();
        break;
      case "Enter":
        if (this.state.highlightedItem) this.select(this.state.highlightedItem);
        break;
      case "ArrowUp":
        this.up();
        break;
      case "ArrowDown":
        this.down();
        break;
    }
  }

  checkVisibility() {
    const { current } = this.list;
    if (!current) {
      return;
    }

    const padding = 0;
    const box = current.getBoundingClientRect();

    const shiftX = box.x < 0 ? -box.x + padding : 0;
    const diffY = box.bottom - window.innerHeight;
    const shiftY = diffY > 0 ? -diffY - padding : 0;

    this.setState({
      shiftCoods: [shiftX, shiftY],
    });
  }

  render() {
    const {
      items,
      selectedItem,
      title,
      align = "left",
      className,
      maxHeight: maxHeightFromProps,
    } = this.props;
    const { highlightedItem, maxListHeight, opened, shiftCoods } = this.state;

    const maxHeight = maxHeightFromProps || maxListHeight;
    const shiftY = shiftCoods[1];
    const translate = shiftY ? -maxHeight : 0;

    return (
      <div className={classnames("dropdown", className)} ref={this.container}>
        <a
          className={"toggle"}
          onClick={(ev) => (opened ? this.close() : this.open(ev))}
        >
          <span>{title}</span>
          <i className="far fa-angle-down" />
        </a>

        {this.state.opened && (
          <div
            tabIndex={0}
            ref={this.list}
            className={`dropdown-menu ${align}-align`}
            style={{
              maxHeight: maxHeight || maxListHeight,
              // Flip dropdown if there's not enough space
              transform: `translateY(${translate}px)`,
            }}
          >
            {items.map((item) => (
              <a
                key={item.key}
                onClick={(ev) => this.select(item, ev)}
                className={classnames(
                  {
                    highlighted: highlightedItem === item,
                    active: item === selectedItem,
                  },
                  item.className
                )}
                onMouseEnter={() => this.highlight(item)}
              >
                {item.title}
              </a>
            ))}
          </div>
        )}
      </div>
    );
  }
}
