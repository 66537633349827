import React, { useCallback } from "react";
import classNames from "classnames";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import { confirmModal } from "@services/syncedModal";

type Props = {
  group: string;
  selectedItems: string[];
  activeItems: string[];
  singularLabel?: string;
  pluralLabel?: string;
  onAddItem?: () => void;
  onToggleActive?: (val: boolean) => void;
  onDelete: (ids: string[]) => void;
  onCopy?: (ids: string[]) => void;
};

const GroupHeader: React.FC<Props> = ({
  group,
  onAddItem,
  onToggleActive,
  onDelete,
  selectedItems,
  activeItems,
  singularLabel = "item",
  pluralLabel = "items",
  onCopy,
}) => {
  const onDeleteHandler = useCallback(async () => {
    const confirmed = await confirmModal("Are you sure?");
    if (!confirmed) return;

    onDelete(selectedItems);
  }, [selectedItems, onDelete]);

  const onToggleActiveHandler = useCallback(
    async (val: boolean) => {
      const confirmed = await confirmModal(
        val
          ? `Activate selected ${pluralLabel}?`
          : `Deactivate selected ${pluralLabel}`
      );
      if (!confirmed) return;

      onToggleActive && onToggleActive(val);
    },
    [onToggleActive]
  );

  const onCopyHandler = useCallback(async () => {
    const confirmed = await confirmModal("Are you sure?");
    if (!confirmed) return;

    onCopy && onCopy(selectedItems);
  }, [selectedItems, onCopy]);

  const activeSelected = selectedItems.every((v) => activeItems.includes(v));
  const inactiveSelected = selectedItems.every((v) => !activeItems.includes(v));

  return (
    <div className={styles.groupHeader}>
      <label>{group}</label>

      {!!selectedItems.length && (
        <div className={styles.selectedItems}>
          <span>
            <span className={styles.green}>
              {selectedItems.length}&nbsp;
              {selectedItems.length > 1 ? pluralLabel : singularLabel}
            </span>
            &nbsp;selected
          </span>

          <a onClick={onDeleteHandler} className={styles.deleteBtn}>
            <i className={"fas fa-trash"} />
          </a>

          {onCopy && (
            <a onClick={onCopyHandler} className={styles.toggleAactiveBtn}>
              <i className={"fas fa-copy"} />
            </a>
          )}

          {onToggleActive && activeSelected && (
            <a
              onClick={() => onToggleActiveHandler(false)}
              className={classNames(styles.toggleAactiveBtn, styles.active)}
            >
              <i className="fas fa-power-off"></i>
            </a>
          )}

          {onToggleActive && inactiveSelected && (
            <a
              onClick={() => onToggleActiveHandler(true)}
              className={styles.toggleAactiveBtn}
            >
              <i className="fas fa-power-off"></i>
            </a>
          )}
        </div>
      )}

      {onAddItem && (
        <a onClick={onAddItem} className={styles.addItem}>
          <i className={"fas fa-plus"} />
          {`Add ${singularLabel}`}
        </a>
      )}
    </div>
  );
};

export default observer(GroupHeader);
