import React from "react";
import "./style.scss";
import { ReactComponent as LogoSvg } from "./logo.svg";
import { observer } from "mobx-react";

const Logo: React.FC<{ sm?: boolean }> = ({ sm }) => {
  return <LogoSvg viewBox={`0 0 ${sm ? 90 : 407} 84`} />;
};

export default observer(Logo);
