import { createBrowserHistory } from "history";

const backupPathKey = "prevPath";

export const backupUrlPath = () => {
  localStorage.setItem(backupPathKey, window.location.pathname);
};

export const getUrlPathBackup = () => localStorage.getItem(backupPathKey);

export const browserHistory = createBrowserHistory();

export const USERS_ROUTE = "users";
