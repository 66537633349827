import { useContext, useEffect } from "react";
import Spinner from "../Common/Spinner";
import NotificationsGroup from "./NotificationsGroup";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import IncidentModal from "../IncidentModal";
import Content from "../Common/Content";

const NotificationsPage = () => {
  const {
    init,
    ready,
    notificationGroups: groups,
    showNotificationModal,
  } = useContext(HomeContext).notificationsStore;

  useEffect(() => {
    init();
  }, []);

  if (!ready) return <Spinner />;

  return (
    <Content>
      {groups.map((group) => (
        <NotificationsGroup group={group} key={group} />
      ))}
      {showNotificationModal && <IncidentModal />}
    </Content>
  );
};

export default observer(NotificationsPage);
