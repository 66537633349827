import React, { useContext } from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import {
  parseAttributeTrigger,
  parseEventName,
  parseFlightStatus,
  parseObjectName,
  parseTurnaroundLengths,
  parseTurnParam,
} from "@services/data";
import classNames from "classnames";
import { capitalize } from "lodash";
import { IncidentModalStoreContext } from "@services/react";
import { MergedTurnaroundAttribute, TriggerKeys } from "@models/incidentConfig";

interface Props {
  data: Parameters<typeof getAttributeShortInfo>[0][];
  error?: boolean;
}

const getAttributeShortInfo = (props: {
  attribute: MergedTurnaroundAttribute;
  parseTimeShift?: boolean;
  trigger?: TriggerKeys;
  required?: boolean;
}) => {
  const { attribute, parseTimeShift = true, required = true, trigger } = props;
  const notSpecified = required ? "Not specified" : "";

  let result = "";
  if (attribute.type === "event") {
    const object = parseObjectName(attribute.objectName);
    const event = parseEventName(attribute.eventName);
    if (!object || !event) {
      return notSpecified;
    }
    result += `${object} ${event}`;
  } else {
    const parameter = parseTurnParam(attribute.parameterName);
    result += `${parameter}`;
  }

  if (trigger) {
    result += `, ${capitalize(parseAttributeTrigger(trigger))}`;
  }

  if (parseTimeShift) {
    const parsedTimeShift = Math.round(attribute.timeShift / 60);
    const timeShift =
      parsedTimeShift >= 0 ? `+${parsedTimeShift}m` : `${parsedTimeShift}m`;
    result += `, ${timeShift}`;
  }

  return result;
};

export const IncidentAttributeCaption: React.FC<Props> = observer(
  ({ data, error = false }) => {
    const result = data.map((v) => getAttributeShortInfo(v)).filter(Boolean);

    return (
      <div className={classNames(styles.caption, { [styles.error]: error })}>
        {result.join(" – ")}
      </div>
    );
  }
);

const getIncludedLabel = (v: "includes" | "excludes") =>
  v === "includes" ? "included" : "excluded";

export const IncidentFilterCaption: React.FC<{}> = observer(({}) => {
  const {
    selectedStands,
    standFilterType,
    selectedAircraftTypes,
    aircraftFilterType,
    turnaroundLength,
    flightStatus,
  } = useContext(IncidentModalStoreContext).filtersStore;

  const result: string[] = [];
  if (flightStatus) {
    result.push(`${capitalize(parseFlightStatus(flightStatus))}`);
  }
  if (turnaroundLength) {
    result.push(`${capitalize(parseTurnaroundLengths(turnaroundLength))}`);
  }
  if (selectedAircraftTypes.length) {
    result.push(
      `Aircrafts ${getIncludedLabel(aircraftFilterType)}: ${
        selectedAircraftTypes.length
      }`
    );
  }
  if (selectedStands.length) {
    result.push(
      `Stands ${getIncludedLabel(standFilterType)}: ${selectedStands.length}`
    );
  }

  return (
    <div className={styles.caption}>
      {result.length ? result.join(" – ") : "All turnarounds"}
    </div>
  );
});
