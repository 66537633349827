import React, { ComponentProps } from "react";
import { PriorityBadge } from "@frontend/assaia-ui";
import { capitalize } from "lodash";
import { IncidentConfig } from "@models/incidentConfig";
import { observer } from "mobx-react";

type PriorityBadgeProps = ComponentProps<typeof PriorityBadge>;

interface Props {
  severity: IncidentConfig["severity"];
}

const SeverityLevel: React.FC<Props> = ({ severity }) => {
  const getPriority = (): PriorityBadgeProps["priority"] => {
    if (severity === "LOW") return "grey";
    else if (severity === "MEDIUM") return "yellow";
    else return "red";
  };

  return (
    <PriorityBadge priority={getPriority()} hoverable={false}>
      {capitalize(severity)}
    </PriorityBadge>
  );
};

export default observer(SeverityLevel);
