import { action, makeAutoObservable, runInAction } from "mobx";
import { DeepPartial, PtsApi } from "@services/types";
import { cloneDeep } from "lodash";
import { NewPts, Pts } from "@models/pts";
import { NEW_PTS } from "@services/constants";
import { customMerge } from "@services/data";
import { IPtsStore } from "./index.types";
import { IHomeStore } from "../HomeStore/index.types";

// TODO: Make separate store for opened PTS page
// and get rid of passing ptsId as argument in methods
export default class PtsStore implements IPtsStore {
  ptsList: Pts[] = [];
  ready = false;
  highlightItemId: string | null = null;

  ptsData: Pts | NewPts | null = null;

  ptsMainModalScreen: "main" | "filters" = "main";

  constructor(private home: IHomeStore, private api: PtsApi) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get ptsTable() {
    return new Map(this.ptsList.map((pts) => [pts.id, pts]));
  }

  getPtsById(ptsId: string | null = "") {
    return this.ptsTable.get(ptsId || "");
  }

  async initPtsList() {
    if (this.ready) return;

    const erCb = (er: Error) => {
      this.home.setUIAlert("Error: something went wrong", "error");
      throw er;
    };

    const promises = [
      this.api.getPtsList().then(this._setPtsList).catch(erCb),
      this.home.initAircrafts().catch(erCb),
    ];

    return Promise.all(promises).then(
      action(() => {
        this.ready = true;
      })
    );
  }

  setSelectedPtsId(id: string | null, airline = this.home.ptsGroups[0] || "") {
    const pts = this.getPtsById(id);

    if (!pts && id === NEW_PTS) {
      this.ptsData = {
        active: true,
        airline,
        schedules: [],
        description: "",
        filters: {
          excludedAircraftTypes: [],
          requiredAircraftTypes: [],
          requiredTurnaroundLength: null,
          inboundFlightStatus: null,
          outboundFlightStatus: null,
        },
      };
      return;
    }

    this.ptsData = cloneDeep(pts || null);
  }

  onPtsChange(data: DeepPartial<Pts>) {
    const pts = this.ptsData;
    if (!pts) {
      return;
    }

    customMerge(pts, data);
  }

  /**
   * Opens modal for editing existing PTS
   */
  editPts(v: PtsStore["ptsData"] = null) {
    this.ptsData = cloneDeep(v);
  }

  closeModal() {
    this.editPts(null);
  }

  async onSubmitPts(pts: PtsStore["ptsData"]) {
    if (!pts) {
      return;
    }

    const newPts = await this.savePts(pts);

    this._updatePts(newPts);

    return newPts;
  }

  async savePts(data: NewPts | Pts) {
    const pts = await ("id" in data
      ? this._handlePatchPts(data)
      : this._handleCreatePts(data));

    pts && this.setHighlightItem(pts.id);

    return pts;
  }

  async deletePtsList(ids: string[]) {
    try {
      await this.api.deletePtsList(ids);

      runInAction(() => {
        this.ptsList = this.ptsList.filter((v) => !ids.includes(v.id));
      });

      this.home.setUIAlert("PTS list has been deleted");
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  async togglePtsActive(ids: string[], active: boolean) {
    const changedPtsList = this.ptsList
      .filter(({ id }) => ids.includes(id))
      .map((pts) => ({ ...pts, active }));

    try {
      await this.api.patchPtsList(changedPtsList);
      changedPtsList.forEach((pts) => this._updatePts(pts));

      this.home.setUIAlert("The changes have been applied");
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  async copyPtsList(ids: string[]) {
    const listToCopy: NewPts[] = this.ptsList
      .filter(({ id }) => ids.includes(id))
      .map(({ id: _, ...rest }) => rest);

    try {
      const newPtsList = await Promise.all(
        listToCopy.map((pts) => this.api.createPts(pts))
      );
      newPtsList.forEach((pts) => this._updatePts(pts));

      this.home.setUIAlert("The changes have been applied");
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  setHighlightItem(id: PtsStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  async onRemoveOperations(ptsId: string, ids: string[]) {
    const pts = cloneDeep(this.getPtsById(ptsId));
    if (!pts) {
      return;
    }

    pts.schedules = pts.schedules.filter((pts) => !ids.includes(pts.id));

    await this.onSubmitPts(pts);
  }

  setPtsMainModalScreen(v: PtsStore["ptsMainModalScreen"]) {
    this.ptsMainModalScreen = v;
  }

  private _setPtsList(items: Pts[]) {
    const { ptsGroups } = this.home;
    this.ptsList = items.filter((v) => ptsGroups.includes(v.airline));
  }

  private async _handleCreatePts(data: NewPts) {
    try {
      const pts = await this.api.createPts(data);

      runInAction(() => this.ptsList.push(pts));

      this.home.setUIAlert("PTS has been added");

      return pts;
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  private async _handlePatchPts(data: Pts) {
    try {
      const pts = await this.api.patchPts(data);

      this._updatePts(pts);

      this.home.setUIAlert("The changes have been applied");

      return pts;
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    }
  }

  private _updatePts(data: Pts) {
    const idx = this.ptsList.findIndex((n) => n.id === data.id);
    if (idx < 0) {
      this.ptsList.push(data);
      return;
    }

    this.ptsList[idx] = data;
  }
}
