import { ModalDropdownSelectOption } from "@frontend/assaia-ui";
import {
  camelCase,
  capitalize,
  merge,
  mergeWith,
  snakeCase,
  uniq,
} from "lodash";
import {
  EMPTY,
  FULL_FLIGHTS_ONLY,
  INBOUNDS_ONLY,
  IncidentConfig,
  SET,
  TOW_OFF,
  TOW_ON,
  triggerKeys,
  TriggerKeys,
  TurnaroundPointInTime,
  turnLengthsKeys,
  UnifiedIncidentConfig,
} from "@models/incidentConfig";
import { PtsEventScheduleOptions } from "@models/pts";
import { getLabels, notificationTriggerLabels } from "./constants";
import { getConfig } from "../di/di";

export function camelCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (ccObj, field) => ({
      ...ccObj,
      [camelCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? camelCaseKeys(obj[field])
          : obj[field],
    }),
    {}
  );
}

export function snakeCaseKeys(obj: any): any {
  return Object.keys(obj).reduce(
    (scObj, field) => ({
      ...scObj,
      [snakeCase(field)]:
        obj[field] &&
        !Array.isArray(obj[field]) &&
        typeof obj[field] === "object"
          ? snakeCaseKeys(obj[field])
          : obj[field],
    }),
    {}
  );
}

export function getLabelFromSnakeCase(str: string) {
  if (getLabels()[str]) return getLabels()[str];
  const res = str.replace(/_/g, " ");
  return res;
}

export const getTriggerLabelData = (n: UnifiedIncidentConfig) => {
  const trigger = n.data.detectedAttributes[0];
  let label = "";
  if (trigger.type === "turnaround_param") {
    label = parseTurnParam(trigger.parameterName);
  } else {
    label = `${getLabelFromSnakeCase(
      trigger.objectName || ""
    )} ${getLabelFromSnakeCase(trigger.eventName || "")}`;
  }

  return {
    label,
    items: [notificationTriggerLabels[n.data.trigger]],
  };
};

export const getTimerLabelData = (n: TurnaroundPointInTime | null) => {
  if (!n) {
    return {
      label: "",
      items: [],
    };
  }

  const { timeShift, turnaroundAttribute } = n;

  let label = "";
  if (turnaroundAttribute.type === "event") {
    label = `${getLabelFromSnakeCase(
      turnaroundAttribute.objectName
    )} ${getLabelFromSnakeCase(turnaroundAttribute.eventName)}`;
  } else {
    label = parseTurnParam(turnaroundAttribute.parameterName);
  }

  return {
    label,
    items: [`${Math.round(timeShift / 60)}m`],
  };
};

export const turnLengthsLabels: Record<typeof turnLengthsKeys[number], string> =
  {
    SHORT: "Short (<140 minutes)",
    LONG: "Long (>140 minutes)",
  };

export const turnStatusesLabels = {
  [TOW_ON]: "Tow on flights only",
  [TOW_OFF]: "Tow off flights only",
  [INBOUNDS_ONLY]: "Tow off and full turn flights only",
  [FULL_FLIGHTS_ONLY]: "Full turn flights only",
};

export const getSelectedTypes = (
  included: string[],
  excluded: string[]
): {
  filterType: "includes" | "excludes";
  selectedTypes: string[];
} => {
  if (included.length) {
    return {
      filterType: "includes",
      selectedTypes: [...included],
    };
  }
  return {
    filterType: "excludes",
    selectedTypes: [...excluded],
  };
};

export const getFilterShortInfo = (config: IncidentConfig) => {
  const {
    requiredAircraftTypes,
    excludedAircraftTypes,
    requiredStands,
    excludedStands,
  } = config;
  const aircraftsFilter = getSelectedTypes(
    requiredAircraftTypes,
    excludedAircraftTypes
  );
  const standsFilter = getSelectedTypes(requiredStands, excludedStands);
  const statusValue = getStatusValue({
    inboundFlightStatus: config.inboundFlightStatus,
    outboundFlightStatus: config.outboundFlightStatus,
  });

  return [
    [
      `${
        aircraftsFilter.filterType === "includes" ? "Included" : "Excluded"
      } aircraft types`,
      aircraftsFilter.selectedTypes.length,
    ],
    [
      `${
        standsFilter.filterType === "includes" ? "Included" : "Excluded"
      } stands`,
      standsFilter.selectedTypes.length,
    ],
    [`Turnaround length`, config.requiredTurnaroundLength],
    [`IN/OUT`, statusValue && parseFlightStatus(statusValue)],
  ] as const;
};

export const getStatusValue = ({
  inboundFlightStatus,
  outboundFlightStatus,
}: Pick<IncidentConfig, "inboundFlightStatus" | "outboundFlightStatus">) => {
  if (inboundFlightStatus === SET && outboundFlightStatus === EMPTY)
    return TOW_OFF;
  if (inboundFlightStatus === EMPTY && outboundFlightStatus === SET)
    return TOW_ON;
  if (inboundFlightStatus === SET && outboundFlightStatus === null)
    return INBOUNDS_ONLY;
  if (inboundFlightStatus === SET && outboundFlightStatus === SET)
    return FULL_FLIGHTS_ONLY;

  return "";
};

export const customMerge = (...args: Parameters<typeof merge>) => {
  // Replace array by target value instead of merge
  const customizer = (objValue: any, targetValue: any) => {
    if (Array.isArray(objValue)) {
      return targetValue;
    }
  };

  mergeWith(...args, customizer);
};

export const snakeToHumanCase = (str: string) =>
  // eslint-disable-next-line no-useless-escape
  capitalize(str.replace(/\_/g, " "));

export const parseObjectName = (objectName: string): string =>
  capitalize(getLabels()[objectName] || snakeToHumanCase(objectName));

export const parseEventName = (eventName: string) => {
  const label =
    getLabels()[eventName] ||
    snakeToHumanCase(eventName)
      .replace(/_/g, " ")
      .replace("connected", "connection")
      .replace("arrived", "arrival")
      .replace("deployed", "deployment")
      .replace(/ed\b/gi, "ing");
  return capitalize(label);
};

export function parseTurnParam(
  parameterName: PtsEventScheduleOptions["referencePoint"]
) {
  const { texts } = getConfig();
  switch (parameterName) {
    case "aircraft_start_ts":
      return "Aircraft stationary";
    case "aircraft_end_ts":
      return "Pushback started";
    case "initial_eobt":
      return texts.parameter_initial_eobt;
    case "eobt":
      return texts.parameter_eobt;
    case "aircraft_or_pushback_ts":
      return texts.parameter_aircraft_or_pushback_ts;
    case "predicted_aircraft_ready_ts":
      return texts.parameter_predicted_aircraft_ready_ts;
    case "aldt":
      return texts.parameter_aldt;
    case "tsat":
      return texts.parameter_tsat;
    case "tobt":
      return texts.parameter_tobt;
    default:
      return parameterName.toUpperCase();
  }
}

export const parseAttributeTrigger = (v: TriggerKeys) => {
  return notificationTriggerLabels[v];
};

export const parseFlightStatus = (v: keyof typeof turnStatusesLabels) => {
  return turnStatusesLabels[v];
};

export const parseTurnaroundLengths = (
  v: keyof typeof turnLengthsLabels | null
) => {
  return v ? turnLengthsLabels[v] : "";
};

export const triggerKeysOptions: ModalDropdownSelectOption<TriggerKeys>[] =
  triggerKeys.map((v) => ({
    title: capitalize(notificationTriggerLabels[v]) || snakeToHumanCase(v),
    id: v,
  }));

export const getTitleByOptions = (
  options: ModalDropdownSelectOption[],
  id: string
) => options.find((v) => v.id === id)?.title;

export function getUniqPropValues<T extends Record<string, any>>(
  items: T[],
  query: Partial<T>,
  prop: keyof T
): string[] {
  return uniq(
    items
      .filter((i) =>
        Object.entries(query).every(([key, value]) => !value || i[key] == value)
      )
      .map((e) => e[prop] || "")
  ).filter(Boolean);
}
